import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class OnBoardingComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.formId = element.querySelector('.yxpType_FORM').dataset.yxpFormId;
        this.data = {telephone: null, dateOfBirth: null};
        this.form = null;
        this.init();
    }

    init() {
        document.addEventListener("yxp-form-loaded", () => {
            this.form = window.youserxp.forms[this.formId];
            youserxp.utils.dateFillHelper(this.element.querySelector('input[name=birthdate]'));
            this.checkFirstStepValidity();
            this.checkLastStepValidity(false);

            this.form.sendFormDataCustom = (formData) => {
                $(this.element).addClass("loading");
                this.subscribeUser().then(
                    () => {
                        Utility.isSDKAuthProviderReady().then(() => {
                            window.authProvider.auth.getLoginStatus().then((d) => {
                                this.notifyLogin(d).then((obj) => {
                                    console.log(obj.status)
                                    window.location.href = "/it/home";
                                }, (err) => {
                                    console.log(err)
                                    window.location.href = "/";
                                    reject();
                                });
                            });
                        });
                    }
                );
            }

            window.authProvider.auth.getLoginStatus().then((d) => {
                $('[name="telephone"]').val(d.data.mobilePhone).trigger('change');
            });

            $('input[name="phone-prefix"]').val('+39');

            $('body').on('input change', (e) => {
                (e.target.name === 'telephone') && this.validateTelephone(e.target);
                (e.target.name === 'birthdate') && ($(e.target).val().length === 14) && this.validateBirthdate($(e.target).val());
                (e.target.name === 'regulation') && this.checkLastStepValidity(e.target.checked);
            });
        });

        this.loadChildComponents(this);
    }

    notifyLogin(data) {
        let url = `/api/lavazza/1.0/auth/disneylandparis/notify/login?uidSig=${data.UIDSignature}&sigTs=${data.signatureTimestamp}`;

        return new Promise((resolve, reject) => {
            youserxp.ajax(url, {
                method: 'GET',
                auth: true
            }).then((obj) => {
                resolve(obj)
            }, (err) => {
                reject(err)
            });
        });
    }

    validateTelephone(target) {
        const phoneValue = target.value.replace(/[^\d]+/g, '');
        $(target).val(phoneValue);
        if ($(target).val().length > 8) {
            this.data.telephone = '+39' + phoneValue;
        } else {
            this.data.telephone = null;
        }
        this.checkFirstStepValidity();
    }

    validateBirthdate(target) {
        const labelNode = document.querySelector('.dateWrapper .yxpInputGroup');
        target ? this.saveSelectedDate(labelNode, target) : this.noDateSelected(labelNode);
    }

    noDateSelected(labelNode) {
        this.form.removeErrorByType(this.getFormField("birthdate"));
        labelNode.classList.remove('hidden');
        this.data.dateOfBirth = null;
        this.checkFirstStepValidity();

    }

    saveSelectedDate(labelNode, target) {
        this.data.dateOfBirth = target.replace(/\s+/g, '');
        labelNode.classList.add('hidden');
        if (!this.isValidAge()) return;
        this.checkFirstStepValidity();
    }

    isValidAge() {
        this.form.removeErrorByType(this.getFormField("birthdate"));
        const age = this.getAgeFromBirthdate(this.data.dateOfBirth);
        if (age >= 18) return true;
        this.form.setErrorByType(this.getFormField("birthdate"), "Devi avere almeno 18 anni");
        document.querySelector('.yxpNextBtn').disabled = true;
        return false;
    }

    getAgeFromBirthdate(birthDate) {
        const today = new Date();
        let age = today.getFullYear() - birthDate.split('/')[2];
        const months = (today.getMonth() + 1) - birthDate.split('/')[1];
        if (months < 0 || (months === 0 && today.getDate() < birthDate.split('/')[0])) age--;
        return age;
    }

    checkFirstStepValidity() {
        let nextButton = document.querySelector('.yxpNextBtn');
        let isDisabled = (!this.data.telephone || this.data.telephone === '' || !this.data.dateOfBirth || this.data.dateOfBirth === '');
        nextButton.disabled = isDisabled;
    }

    checkLastStepValidity(regulationValue) {
        let submitButton = document.querySelector('.yxpSubmitBtn');
        submitButton.disabled = !regulationValue;
    }

    subscribeUser() {
        const data = {...this.data, dateOfBirth: this.data.dateOfBirth};
        return new Promise((resolve, reject) => {
            youserxp.ajax('/api/1.0/lavazza/campaign/disneylandparis/subscribe', {
                method: 'POST',
                auth: true
            }, data, "json").then((obj) => {
                if (obj.body) resolve();
                else {
                    $(this.element).removeClass("loading");
                    this.form.setErrorByType(this.getFormField("regulation", 1), "Ops, qualcosa è andato storto. Riprova.");
                    reject();
                }
            }, () => {
                $(this.element).removeClass("loading");
                this.form.setErrorByType(this.getFormField("regulation", 1), "Ops, qualcosa è andato storto. Riprova.");
                reject();
            });
        });
    }

    getFormField(attrName, step = 0) {
        return this.form.formJson.steps[step].fields.filter(f => f.config.attrName === attrName)[0];
    }
}

export default OnBoardingComponent;