import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class SurveyPageComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.endpoint = '/api/1.0/loyalty/campaign/disneylandparis/mission/survey/status';

        this.surveyEntryPoint = this.element.querySelector('.survey-start');
        this.surveyBlocked = this.element.querySelector('.survey-blocked');
        this.formWrapper = this.element.querySelector('.form-wrapper');
        this.init();
    }


    checkSurveyMission() {
        this.element.classList.add('loading');
        youserxp.ajax(this.endpoint, {
            method: 'GET',
            auth: true
        }).then(
            (data) => this.setSurveyVisibility(data),
            (err) => console.error(err)
        );
    }


    setSurveyVisibility(data) {
        data.body.canExecuteMission ? this.startSurvey() : this.blockSurvey();
        this.element.classList.remove('loading');
    }


    startSurvey() {
        this.surveyEntryPoint.classList.remove('hidden');
        this.surveyEntryPoint.classList.add('visible');
        this.surveyEntryPoint.querySelector('.yxpLink').addEventListener('click', () => this.showForm());
    }

    showForm() {
        this.element.querySelector('.visual').classList.add('hidden');
        this.surveyEntryPoint.classList.remove('visible');
        setTimeout(() => {
            this.surveyEntryPoint.classList.add('hidden');
            this.formWrapper.classList.remove('hidden');
            this.formWrapper.classList.add('visible');
        }, 300);
    }

    blockSurvey() {
        this.surveyBlocked.classList.remove('hidden');
        this.surveyBlocked.classList.add('visible');
    }

    listenSubmit() {
        document.addEventListener('yxp-form-submit-ok', (e) => {
            const url = '/it/reserved/instant-win?from=survey&code=' + e.detail.resultMission.RESULT_MISSION.id;
            Utility.buildTyp(url);
        })
    }

    init() {
        this.checkSurveyMission();
        this.listenSubmit();
    }
}

export default SurveyPageComponent;
