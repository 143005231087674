import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class InstantWinComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.code = Utility.getUrlParameter("code");
        this.type = Utility.getUrlParameter("from");
        this.campaignCode = 'disneylandparis';
        this.isPlayInProgress = false;
        this.email = '';
        this.gifImages = ['/fe-web/img/disneylandparis/win.gif', '/fe-web/img/disneylandparis/lost.gif']

        this.init();
    }

    init() {
        this.element.classList.add('loading');
        Promise.all([this.getMissionResult(), this.preloadGif()]).then((obj) => {

            if (typeof obj[0].body.WINNER == 'undefined') {
                if (this.isPlayInProgress) return;
                this.isPlayInProgress = true;
                let missionResultCode = obj[0].body.missionCode;

                this.completeMission(missionResultCode).then((obj) => {
                    this.isPlayInProgress = false;
                    this.buildAnswer(obj.body);
                }, (err) => {
                    this.isPlayInProgress = false;
                    console.log(err);
                })

            } else {
                this.buildAnswer(obj[0].body);
                this.element.classList.remove('loading');
            }
        }).catch((er) => {
            this.element.classList.remove('loading');
            $('.award-result').append(`
                <div class="yxpRow">
                    <div class="yxp-cols-12 yxpBlock yxpType_TEXTAREA content-wrapper-cnt w600 text-center">
                        <div class="yxpHtmlTextWrapper">
                            <h4>Ops, qualcosa è andato storto! </h4><br>
                            Puoi riprovare in qualsiasi momento accedendo all’area dedicata alle tue giocate.
                        </div>
                    </div>
                </div>
                <div class="yxpRow">
                    <div class="yxp-cols-12 yxpBlock yxpType_LINK mb-60 button mt-20 text-center">
                        <a href="/it/reserved/premi-e-giocate" target="_self">GUARDA LE TUE GIOCATE</a>
                    </div>
                </div>
            `);
        })
    }

    buildAnswer(answer) {
        if (answer.WINNER === true) {
            Utility.isSDKAuthProviderReady().then(() => {
                window.authProvider.auth.getLoginStatus().then((d) => {
                    this.email = d.profile.email;
                    this.buildWinAnswer(answer);
                    this.element.classList.remove('loading');
                });
            });
        } else {
            this.buildLoseAnswer(answer);
            this.element.classList.remove('loading');
        }
    }


    buildLoseAnswer(answer) {
        const attempts = answer.playsLeft;
        const labelGiocate = attempts === 1 ? 'GIOCATA' : 'GIOCATE';
        const pageUrl = window.location.href;

        let html = `
            <div class="instant-win__info text-center focus-answer">
                <div>Ci dispiace ma...</div>
                <h1>NON HAI VINTO</h1>
                <img alt="non hai vinto" src="/fe-web/img/disneylandparis/lost.gif"/>
            </div>`;

        if (!attempts) html += `
            <div class="instant-win__info text-center">
                <div>
                    <b>Conserva il documento d’acquisto e le confezioni dei prodotti Lavazza </b> con cui hai effettuato la giocata: 
                    ti verrà richiesto per verifica in originale. Potrebbe essere richiesta 
                    <b>anche in caso di vincita per l’estrazione finale.</b>
                </div>

                <div class="yxp-cols-12 yxpBlock yxpType_LINK">
                     <a href="/it/reserved/gioca" target="_self">TORNA ALLA PAGINA GIOCA E VINCI</a>
                </div>
            </div>`;

        if (attempts) html += `
            <div class="instant-win__info text-center blue-wave">
                <div>
                    Questa volta non è andata, ma hai altre occasioni<br>
                    per tentare la fortuna!
                </div>
                
                <b>HAI ANCORA ${attempts} ${labelGiocate}</b><br><br>
                                    
                <div class="yxp-cols-12 yxpBlock yxpType_LINK button">
                    <a href="${pageUrl}" target="_self">RITENTA LA FORTUNA</a>
                </div>
            </div>
            
            
            <div class="yxp-cols-12 yxpBlock yxpType_CUSTOM instant-win__try-again blue-background text-center">
                <div class="yxpImageTextWrapper">
                    <div class="yxpHtmlTextWrapper">
                        <h3>VUOI PROVARE IN UN ALTRO MOMENTO?</h3>
                        <br><br>
                        <div>Puoi riprendere la tua giocata quando vuoi, all’interno della sezione “Premi e giocate”.</div>
                    </div>
                    
                    <div class="yxp-cols-12 yxpBlock yxpType_LINK generic-link">
                        <a href="/" target="_self">VOGLIO GIOCARE IN SEGUITO</a>
                    </div>
                </div>
                
                <div class="yxp-cols-12 yxpBlock yxpType_TEXTAREA receipt-disclaimer">
                    <p><strong>Conserva il documento d’acquisto</strong> 
                    con cui hai effettuato la giocata: ti verrà richiesto per verifica in originale. Potrebbe essere richiesto 
                    <strong>anche in caso di vincita per l’estrazione finale.</strong></p>
                </div>
           
                <div class="yxp-cols-12 yxpBlock yxpType_LINK generic-link">
                     <a href="/it/reserved/gioca" target="_self">TORNA ALLA PAGINA GIOCA E VINCI</a>
                </div>
            </div>
        `;

        this.animateAndScrollHtml(html);
    }

    animateAndScrollHtml(html) {
        this.element.innerHTML = html;
        this.scrollToResponse();
        setTimeout(() => {
            const wrappers = this.element.querySelectorAll('.instant-win__info, .instant-win__try-again, .instant-win__reward');
            wrappers.forEach((element) => element.classList.add('pop'));
        }, 5000);
    }


    buildWinAnswer(answer) {
        const attempts = answer.playsLeft;
        let html = `
            <div class="instant-win__info rainbow-wave text-center focus-answer">
                <h1 class="win">HAI VINTO!</h1>
                <img alt="hai vinto" src="/fe-web/img/disneylandparis/win.gif"/>
                
                <div>
                    Verrai contattato all’indirizzo <b>${this.email}</b>
                    con tutti i dettagli per ricevere il premio.
                </div>
            </div>
            
            <div class="instant-win__reward gray-background ${attempts ? 'blue-wave' : ''}">
                <div class="yxpImageWrapper">
                    <img src="/fe-web/img/disneylandparis/cofanetti.png" alt="" title="">
                </div>
                
                <div class="yxp-cols-12 yxpBlock yxpType_TITLE">
                    <h2 class="yxpTitle yxpH2">100 cofanetti<br>Cappuccino da eroi</h2>
                </div>
                
                <div class="yxp-cols-12 yxpBlock subtitle">
                    <h3>In palio 100 cofanetti Cappuccino da eroi, il set composto da 2 tazze cappuccino Lavazza e uno <i>stencil</i> in acciaio del Marvel Avengers Campus.</h3>
                </div>
                
                <div class="yxp-cols-12 yxpBlock yxpType_LINK button">
                    <a href="/it/reserved/richiesta-premio?yxp-loyalty-ri=${answer.REWARD?.id}" target="_self">richiedi il premio</a>
                </div>

                <div class="yxp-cols-12 yxpBlock yxpType_TEXTAREA">
                    <div class="yxpHtmlTextWrapper">Hai 10 giorni per richiedere il premio</div>
                </div>
                
                <div class="yxp-cols-12 yxpBlock yxpType_TEXTAREA receipt-disclaimer">
                    <div class="yxpHtmlTextWrapper">
                        <p><strong>Conserva il documento d’acquisto</strong> 
                        con cui hai effettuato la giocata: ti verrà richiesto per verifica in originale. Potrebbe essere richiesto 
                        <strong>anche in caso di vincita per l’estrazione finale.</strong></p>
                    </div>
                </div>
           
                <div class="yxp-cols-12 yxpBlock yxpType_LINK generic-link">
                     <a href="/it/reserved/gioca" target="_self">TORNA ALLA PAGINA GIOCA E VINCI</a>
                </div>
            </div>
        `;

        if (attempts) html += this.getPlaysLeftWinner(attempts);
        this.animateAndScrollHtml(html);
    }


    getPlaysLeftWinner(attemptsNumber) {
        const labelGiocate = attemptsNumber === 1 ? 'GIOCATA' : 'GIOCATE';
        const pageUrl = window.location.href;

        return `
            <div class="yxp-cols-12 yxpBlock yxpType_CUSTOM instant-win__try-again blue-background text-center">
                <div class="yxpImageTextWrapper">
                    <div class="yxpHtmlTextWrapper">
                        <h3>HAI ANCORA</h3>
                        <div class="instant-win__counter">${attemptsNumber}</div>
                        <h3>${labelGiocate}</h3>
                    </div>
                    
                    <div class="yxp-cols-12 yxpBlock yxpType_LINK button-white">
                        <a href="${pageUrl}" target="_self">RITENTA LA FORTUNA</a>
                    </div>
                </div>
            </div>
        `;
    }


    scrollToResponse() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    preloadGif() {
        return this.gifImages.map(i => Utility.preloadImage(i));
    }

    getMissionResult() {
        let url = `/api/1.0/lavazza/campaign/${this.campaignCode}/instantwin/${this.type}/${this.code}`;
        return new Promise((resolve, reject) => {
            youserxp.ajax(url, {
                method: 'POST',
                auth: true
            }).then(
                (obj) => resolve(obj),
                (err) => reject(err));
        });
    }

    completeMission(missionCode) {
        let url = `/api/1.0/loyalty/campaign/${this.campaignCode}/mission/${missionCode}/completed`;
        let payload = {
            missionPayload: {
                campaign: this.campaignCode,
                site: "disneylandparis"
            }
        }

        return new Promise((resolve, reject) => {
            youserxp.ajax(url, {
                method: 'POST',
                auth: true
            }, payload, "json").then(
                (obj) => resolve(obj),
                () => reject());
        })
    }
}

export default InstantWinComponent;
