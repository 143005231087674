import {SmartComponent} from "smart-component-js";

class DefaultCtrl extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.el = element;
        this.init();
    }

    init() {
        this.loadChildComponents(this);
        document.addEventListener("yxp-form-loaded", () => {
            this.loadChildComponents(this);
        });
    }
}

export default DefaultCtrl;
