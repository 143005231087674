import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class SurveyComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.endpoint = '/api/1.0/loyalty/campaign/disneylandparis/mission/survey/status';
        this.init();
    }


    checkLoginStatus() {
        Utility.isSDKAuthProviderReady().then(() => {
            window.authProvider.auth.getLoginStatus().then((d) => {
                this.checkSurveyMission();
            });
        });
    }


    checkSurveyMission() {
        youserxp.ajax(this.endpoint, {
            method: 'GET',
            auth: true
        }).then(
            (data) => this.setBoxVisibility(data),
            (err) => console.error(err)
        );
    }


    setBoxVisibility(data) {
        if (!data.body.completionDate) return;
        this.element.classList.add('hidden');
    }

    init() {
        this.checkLoginStatus();

    }
}

export default SurveyComponent;
