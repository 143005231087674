import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class HomeComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.header = null;
        this.userName = null;
        this.sdkAuthProviderReadyPromise = null;
        this.init();
    }


    checkLogin() {
        $(document).ready(() => {
            Utility.isSDKAuthProviderReady().then(() => {
                window.authProvider.auth.getLoginStatus().then((d) => {
                    this.userName = d.profile.firstName;
                    this.header = $('[component="MenuComponent"]')[0];
                    this.homeComponent = $('[component="HomeComponent"]')[0];
                    $('.home').addClass('logged');
                });
            });
            $('body').removeClass('loading');
        });
    }


    init() {
        $('body').addClass('homePage loading');
        this.checkLogin();
    }
}

export default HomeComponent;
