import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class GiocaCodiceComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.init();
    }

    init() {
        this.renderCouponWidget();
        this.addWidgetListener();
    }

    addWidgetListener() {
        document.addEventListener('yxp-loyalty-coupon-ok', (e) => {
            const response = e.detail.body;
            const url = '/it/reserved/instant-win?from=coupon&code=' + response.missionOutput.RESULT_MISSION.id;
            Utility.buildTyp(url);
        })
    }

    renderCouponWidget() {
        youserxp.widget.coupon({
            selector: "[customwidget='giocaCodiceDisneyParis']",
            forceCodeToUppercase: true,
            landingCta: {
                url: "$!EditorManager.getInternalLink($data.content.landingCta.id)",
                label: "$!EditorManager.escapeHtml(${data.content.landingCta.text})",
                target: "$!{data.config.landingCta.link.target}"
            },
            loyaltyConfig: {
                loyalty: {
                    campaignCode: 'disneylandparis'
                },
                endpoints: {
                    burnCoupon: '/api/1.0/lavazza/campaign/disneylandparis/[couponCode]/redeem',
                    configuration: '/api/1.0/loyalty/campaign/disneylandparis/coupon/configuration/disneyland-paris-capsule-coupon'
                }
            },
            translations: {
                "intro.title": 'Inserisci qui il Codice',
                "intro.error": 'Il codice inserito non è valido o è già stato utilizzato',
                "intro.errorValidation": 'Formato non valido, digitare un codice di 16 caratteri. Controlla e riprova.',
                "intro.cta": 'CONFERMA',
                "ok.title": 'Codice inserito correttamente.',
                "ok.text": 'Grazie per l’acquisto!'
            }
        });
    }
}

export default GiocaCodiceComponent;
