import {SmartComponent} from "smart-component-js";

class MenuComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);

        this.init();
    }

    init() {
        $(this.element).find('#openMenu').click((e) => {
            this.openMenu()
        })
        $(this.element).find('#closeMenu').click((e) => {
            this.closeMenu()
        });

        this.loadChildComponents(this);
    }

    openMenu() {
        $('body').addClass('no-scroll');
        $(this.element).find('.menu').addClass('open');
    }

    closeMenu() {
        $('body').removeClass('no-scroll');
        $(this.element).find('.menu').removeClass('open');
    }
}

export default MenuComponent;
