import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";


class ScreensetComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        window.BE_LAVAZZA_CONSTANTS = {};
        params = params || {};
        params.className = "ScreensetComponent";
        super(element, parentComponent, params);
        this.body = $(element);
        const wrapper = document.querySelector('div[data-window]');
        this.window = wrapper?.dataset.window || "gigya-login-screen";
        if (this.window !== "gigya-login-screen") this.createScreenset(this.window);
        this.init();
    }

    init() {
        $(document).on('click', 'header button.login, div[data-button="login"] a, .login-container a.visual-component__anchor', (e) => {
            e.preventDefault();
            this.createScreenset("gigya-login-screen");
        });
        this.redirectLogin();
    }

    redirectLogin() {
        this.param = Utility.getUrlParameter("login");
        if (this.param) {
            this.createScreenset("gigya-login-screen");
        }
    }

    // getScreensetInfo() {
    //     if (this.screenset) {
    //         var context = {
    //             campaign: BE_LAVAZZA_CONSTANTS.CAMPAIGN_TAG,
    //             parentUID: "",
    //             regSource: window.location.href
    //         };
    //         if (!!Utility.getUrlParameter("token")) {
    //             GigyaManager.getFriendInfo().then((data) => {
    //                 if (data && data.body && data.status === "OK") {
    //                     context.parentUID = data.body.uid;
    //                     this.createScreenset(context);
    //                 }
    //             });
    //         } else {
    //             this.createScreenset(context);
    //         }
    //     }
    // }

    createScreenset(windowParams) {
        window.showScreenSet("MainAthena-RegistrationLogin", "lvz-gigya__container", windowParams, {
            language: "it",
            phoneField: false,
            onSubmit: (eventObj) => {
                let formname = eventObj.form;
                this.trackThings(formname, eventObj);
                if (eventObj.form == "gigya-register-form" || eventObj.form == "gigya-complete-registration-form" || eventObj.form == "gigya-profile-form") {
                    let today = Utility.formatDate(false, "yyyy-mm-dd");

                    if (eventObj.formModel.profile && eventObj.formModel.profile.firstName) {
                        eventObj.formModel.profile.firstName = Utility.formatName(eventObj.formModel.profile.firstName);
                    }
                    if (eventObj.formModel.profile && eventObj.formModel.profile.lastName) {
                        eventObj.formModel.profile.lastName = Utility.formatName(eventObj.formModel.profile.lastName);
                    }
                    if (!eventObj.formModel.data.regSource && eventObj.context.regSource) {
                        eventObj.formModel.data.regSource = eventObj.context.regSource;
                    }
                    if (eventObj.formModel.data.privacyAcceptance) {
                        eventObj.formModel.data.privacyAcceptanceDate = today;
                    }
                    if (eventObj.formModel.data.privacyProfiling) {
                        eventObj.formModel.data.privacyProfilingDate = today;
                    }
                    if (eventObj.formModel.data.privacyRecall) {
                        eventObj.formModel.data.privacyRecallDate = today;
                    }
                    if (eventObj.formModel.data.privacyRecall) {
                    }

                    eventObj.formModel.data.loginChannel = {};
                    eventObj.formModel.data.loginChannel.channel_iwin = true
                }

            },
            onAfterScreenLoad: (eventObj) => {
                if (!!eventObj && !!eventObj.currentScreen) {
                    if (eventObj.currentScreen === 'gigya-login-screen') {
                        utag.data['page_name'] = 'it:concorso-lv-disneylandparis:login';
                        utag.data['page_section'] = 'accedi';
                        utag.data['page_type'] = 'account';
                        utag.view(utag.data);
                    } else if (eventObj.currentScreen === 'gigya-register-screen') {
                        utag.data['page_name'] = 'it:concorso-lv-disneylandparis:registrazione:step1';
                        utag.data['page_section'] = 'registrazione';
                        utag.data['page_type'] = 'account';
                        utag.data['event_name'] = "concorso_registrazione_step1";
                        utag.view(utag.data);
                    }
                }
            },
            onAfterSubmit: (eventObj) => {
                let formname = eventObj.form;
                if (eventObj.form === "gigya-register-form" || eventObj.form === "gigya-profile-form") {
                    let loginProvider = '';
                    if (!eventObj.response.socialProviders) {
                        loginProvider = 'standard';
                    } else {
                        //prendo il primo
                        loginProvider = eventObj.response.socialProviders.split(',')[0];
                    }

                    if (loginProvider === 'googleplus') {
                        loginProvider = 'google';
                    }
                    if (loginProvider === 'site') {
                        loginProvider = 'standard';
                    }

                    let profiling = false;
                    let recall = false;
                    try {
                        recall = eventObj.response.preferences.privacyRecallConsent.isConsentGranted;
                        profiling = eventObj.response.preferences.privacyProfilingConsent.isConsentGranted;
                    } catch (e) {

                    }

                    // UTAGTRACK - registrazione completata
                    utag.data['page_name'] = 'it:concorso-lv-disneylandparis:registrazione:ok';
                    utag.data['page_section'] = 'registrazione';
                    utag.data['page_type'] = 'account';
                    utag.data['registration_type'] = loginProvider;
                    utag.data['privacy_invio_promo'] = recall;
                    utag.data['privacy_profilazione'] = profiling;
                    utag.data['event_name'] = "concorso_registrazione_ok";
                    utag.view(utag.data);
                }

            }
        });

        gigya.socialize.addEventHandlers({
            onLogin: (eventObj) => {
                let loginMode = eventObj.provider;
                if (loginMode === 'googleplus') {
                    loginMode = 'google';
                }
                if (loginMode === 'site') {
                    loginMode = 'standard';
                }

                utag.data['login_type'] = loginMode;
                utag.data['event_name'] = "concorso_login_ok";
                utag.link(utag.data);

                gigya.accounts.getAccountInfo({
                    callback: (d) => {
                        gigya.accounts.setAccountInfo({data: {loginChannel: {channel_iwin: true}}})
                    }
                });
            }
        });
    }

    errorHandler(eventObj) {
        if (eventObj != null) {
            console.log("failed with error: " + eventObj.errorMessage);
        }
    }


    trackThings(formname, eventObj) {
    }
}

export default ScreensetComponent;
