import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class ReceiptComponent extends SmartComponent {
    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.type = element.getAttribute('receipt-type');
        this.init();
    }

    showReceiptInfoPopup() {
        let popupContainer = document.querySelector('.receipt-sample-popup');
        popupContainer.classList.add('visible');
        document.body.classList.add('no-scroll');
        popupContainer.querySelector('.yxpLink').addEventListener('click', () => {
            popupContainer.classList.remove('visible');
            document.body.classList.remove('no-scroll');
        });
    }

    showThankyouPage(e) {
        const url = '/it/reserved/instant-win?from=receipt&code=' + e.detail.body.missionOutput.RESULT_MISSION.id;
        Utility.buildTyp(url);
    }

    addWidgetListener() {
        document.addEventListener('yxp-loyalty-receipt-info-clicked', () => this.showReceiptInfoPopup());
        document.addEventListener('yxp-loyalty-receipt-ok', (event) => this.showThankyouPage(event));
    }


    init() {
        this.addWidgetListener();
    }
}

export default ReceiptComponent;
