var Utility = {
    forbiddenWords: [
        "allah",
        "allupato",
        "allupata",
        "allupate",
        "allupati",
        "ammucchiata",
        "ammucchiate",
        "anal",
        "anale",
        "anali",
        "anoressico",
        "anoressica",
        "ano",
        "ani",
        "arrapato",
        "arrapata",
        "arrapati",
        "arrapate",
        "arrusa",
        "arruso",
        "assatanato",
        "asshole",
        "bagascia",
        "bagascie",
        "bagassa",
        "baggiana",
        "bagnarsi",
        "baldracca",
        "baldracche",
        "balengo",
        "balle",
        "balusa",
        "batacchio",
        "battona",
        "battone",
        "bastardo",
        "bastarda",
        "bastardi",
        "bastarde",
        "belino",
        "belin",
        "belino",
        "bega",
        "bitch",
        "blow job",
        "bocchinara",
        "bocchinare",
        "bocchinaro",
        "bocchinari",
        "bocchino",
        "bocchini",
        "bofilo",
        "boiata",
        "bordello",
        "bottana",
        "brinca",
        "brucapelo",
        "bucaiolo",
        "buco",
        "buchi",
        "bullo",
        "bulla",
        "bulli",
        "buliccio",
        "buona donna",
        "busone",
        "cacca",
        "cacche",
        "caccone",
        "caccati in mano e prenditi a schiaffi",
        "caciocappella",
        "cadavere",
        "cagare",
        "cago",
        "cagata",
        "cagate",
        "cagna", "cazzimma",
        "cazzino",
        "cazzo",
        "cazzola",
        "cazzone",
        "cazzoni",
        "checca",
        "checche",
        "chiappa",
        "chiavo",
        "chiava",
        "chiavare",
        "chiavata",
        "ciccione",
        "cicciona",
        "ciccioni",
        "ciccione",
        "ciospo",
        "ciucciami",
        "ciuccia",
        "cock",
        "coglione",
        "coglioni",
        "cogliona",
        "cornuto",
        "cornuta",
        "cornute",
        "cornuti",
        "cozza",
        "cretino",
        "cretina",
        "cretini",
        "cretine",
        "cristo",
        "culattina",
        "culattone",
        "culattoni",
        "culo",
        "culone",
        "culona",
        "culi",
        "di merda",
        "diavolo",
        "dildo",
        "dio",
        "dirty",
        "ditalino",
        "ditalini",
        "duro",
        "eiaculazione",
        "escort",
        "fanculo",
        "fascista",
        "fascisti",
        "fava",
        "fica",
        "fiche",
        "figa",
        "figazza",
        "fighe",
        "figlio di buona donna",
        "figlio di puttana",
        "figone",
        "finocchio",
        "fottere",
        "fottona",
        "fottersi",
        "fottiti",
        "fotto",
        "fotti",
        "fracicone",
        "fregna",
        "frocio",
        "froci",
        "frocione",
        "froscio",
        "fuck",
        "fucker",
        "fucking",
        "gesu",
        "ges",
        "gnocca",
        "goldone",
        "grasso",
        "grassa",
        "grassi",
        "grasse",
        "grassone",
        "grassoni",
        "grilletto",
        "guanto",
        "guardone",
        "ignorante",
        "ingnoranti",
        "imbecille",
        "imbecilli",
        "immigrati",
        "immigrato",
        "incazzarsi",
        "incazzati",
        "incazzo",
        "incoglionirsi",
        "inculare",
        "inculo",
        "incula",
        "ingoio",
        "ingoia",
        "isis",
        "islam",
        "islamico",
        "l'arte bolognese",
        "leccaculo",
        "lecchino",
        "lesbica",
        "lesbiche",
        "lecca",
        "leccamelo",
        "leccamela",
        "leccami",
        "lecco",
        "leccaciuffo",
        "limonare",
        "lofare",
        "loffa",
        "loffare",
        "lumaca",
        "lurida",
        "lurido",
        "madonna",
        "maiala",
        "maiale",
        "maiali",
        "maialona",
        "maialoni",
        "manico",
        "mannaggia",
        "maometto",
        "matello",
        "merda",
        "merde",
        "merda secca",
        "meretrice",
        "merdaccia",
        "merdata",
        "merdina",
        "merdone",
        "merdoso",
        "mierda",
        "milf",
        "milfone",
        "milfona",
        "mignotta",
        "mignotte",
        "mignottone",
        "minchia",
        "minchione",
        "minnaÊ",
        "minne",
        "mona",
        "monta",
        "monto",
        "montare",
        "mussa",
        "nave scuola",
        "nerchia",
        "negro",
        "negra",
        "negri",
        "negre",
        "negrone",
        "negroni",
        "nuda",
        "nudi",
        "nudo",
        "nude",
        "olio di palma",
        "palma",
        "obesa",
        "obesi",
        "obese",
        "obeso",
        "orgia",
        "orgie",
        "padulo",
        "palle",
        "palloso",
        "passera",
        "passerina",
        "patacca",
        "patata",
        "patonza",
        "pecorina",
        "pedofilo",
        "pedofilia",
        "pelosa",
        "peloso",
        "pelose",
        "pelo",
        "peli",
        "pene",
        "peni",
        "pesce",
        "picio",
        "piglianculo",
        "piglia in culo",
        "pincare",
        "pipa",
        "pipi",
        "pip“",
        "pippa",
        "pippone",
        "pirla",
        "pisciare",
        "piscio",
        "pisci",
        "pisello",
        "piselli",
        "pistola",
        "pistolotto",
        "pomiciare",
        "pompa",
        "pompino",
        "pompinara",
        "poppe",
        "porca",
        "porca madonna",
        "porca miseria",
        "porca puttana",
        "porco",
        "porcona",
        "porcone",
        "porco zio",
        "porco dio",
        "potta",
        "puppami",
        "puta",
        "puttana",
        "puttanone",
        "puttane",
        "puttaniere",
        "preservativo",
        "profilattico",
        "profilattici",
        "preservativi",
        "prostituta",
        "prostitute",
        "quaglia",
        "randello",
        "recchione",
        "ricchione",
        "ricchioni",
        "rincoglionire",
        "rincoglionita",
        "rincoglionito",
        "rizzarsi",
        "rizza",
        "rizzacazzi",
        "rompiballe",
        "rottoinculo",
        "ruffiano",
        "rumenta",
        "salciccia",
        "satana",
        "sbattere",
        "sbattersi",
        "sbattimi",
        "sborra",
        "sboro",
        "sborare",
        "sborrata",
        "sborrone",
        "sbrodolata",
        "scopare",
        "scopiamo",
        "scopo",
        "scopi",
        "scopa",
        "scopata",
        "scorreggiare",
        "scorreggia",
        "scoreggio",
        "scorreggione",
        "scorreggiona",
        "sega",
        "seghe",
        "sesso",
        "sessuale",
        "sessuali",
        "sfigato",
        "sfigata",
        "sfigati",
        "sfigate",
        "slinguare",
        "slinguata",
        "slut",
        "smandrappata",
        "soccia",
        "socmel",
        "sorca",
        "sgualdrina",
        "sgualdrine",
        "spagnola",
        "sperma",
        "spermicida",
        "spompinare",
        "spompino",
        "spompini",
        "spompina",
        "sticchio",
        "stronzi ",
        "stupido",
        "stupida",
        "stupidi",
        "stupide",
        "stronza",
        "stronze",
        "stronzata",
        "stronzate",
        "stronzo",
        "suca",
        "succhiami",
        "succhia",
        "sveltina",
        "sverginare",
        "svergare",
        "svergo",
        "tarzanello",
        "terrone",
        "terroni",
        "terrorista",
        "terroristi",
        "testa di cazzo",
        "testicolo",
        "testicoli",
        "tetta",
        "tette",
        "tettina",
        "tettine",
        "tettona",
        "tettone",
        "topa",
        "transessuale",
        "travone",
        "troia",
        "troie",
        "troione",
        "troiona",
        "trombare",
        "trombo",
        "tromba",
        "uccello",
        "vacca",
        "vaccaputtana",
        "vaccatroia",
        "vacche",
        "vaffanculo",
        "vagina",
        "vagine",
        "vangare",
        "verga",
        "vergine",
        "vergini",
        "viadone",
        "viadoni",
        "vibratore",
        "vibratori",
        "viados",
        "zinna",
        "zinne",
        "zio cantante",
        "zoccola",
        "zoccolone",
        "zoticone"
    ],


    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    getCurrentUri() {
        return window.location.href.replace(window.location.origin, "");
    },

    getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            sParameterValue,
            index,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            index = sURLVariables[i].indexOf('=');
            sParameterName = sURLVariables[i].slice(0, index);
            sParameterValue = sURLVariables[i].slice(index + 1);

            if (sParameterName === sParam) {
                return sParameterValue === undefined ? true : sParameterValue;
            }
        }
    },

    isSDKAuthProviderReady() {
        if (!this.sdkAuthProviderReadyPromise) {
            this.sdkAuthProviderReadyPromise = new Promise((resolve, reject) => {
                let timer = setInterval(() => {
                    if (window.authProvider) {
                        clearInterval(timer);
                        resolve();
                    }
                }, 20);
            })
        }
        return this.sdkAuthProviderReadyPromise;
    },

    preloadImage(src) {
        new Promise(r => {
            const image = new Image();
            image.onload = r;
            image.onerror = r;
            image.src = src;
        })
    },

    formatDate(timestamp, format) {
        let date;
        if (!timestamp) {
            date = new Date();
        } else {
            date = new Date(timestamp);
        }
        if (!format || format === "yyyy-mm-dd") {
            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else if (format === "dd-mmm-yyyy") {
            let monthNames = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"];
            return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
        }
    },
    formatCompleteDate(date) {
        let d = new Date(date);
        let off = d.getTimezoneOffset() * 60000
        let newdt = new Date(d - off).toISOString()
        return newdt.replace('Z', '').replace('T', ' - ');
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatName(string) {
        var tmp = string.charAt(0).toUpperCase() + string.slice(1);
        tmp = tmp.replace(/[^a-zA-Z ]/g, "");
        return tmp;
    },
    isMobile() {
        return $(window).width() < 768;
    },
    isNotValidText(string) {
        if (string !== undefined && string != null && string.length > 1) {
            var splitted = string.split(" ");
            for (var y = 0; y < splitted.length; y++) {
                for (var i = 0; i < this.forbiddenWords.length; i++) {
                    if (splitted[y].toLowerCase() == this.forbiddenWords[i]) {
                        return true;
                    }
                }
            }
        }
        return false;
    },
    filepath2Url(path) {
        let reg = /^(.*?)(\bwebapp\b)(.*)$/;
        let find = path.match(reg);
        if (find.length() == 3) {
            return BE_LAVAZZA_CONSTANTS.MEDIA_HOSTNAME + find[2];
        } else {
            return false;
        }
    },
    isFacebookBrowser() {
        var ua = navigator.userAgent || navigator.vendor || window.opera;
        return /FBAN|FBAV/i.test(ua);
    },

    buildTyp(url) {
        $('[component="DefaultCtrl"] > .yxpRow:nth-child(2)').html(`
            <div class="iw-thank-you-page">
                <div class="iw-thank-you-page__image">
                     <img src="/fe-web/img/disneylandparis/background/iw-disney.png" alt="instant win">
                </div>
                <div class="iw-thank-you-page__info">
                    <p>È il tuo momento...</p>
                    <h3>ORA TENTA LA FORTUNA!</h3>
                    <p><strong>Clicca su "Scopri se hai vinto"</strong> <br>
                    per sapere se ti sei aggiudicato uno dei premi in palio!</p>
                    <a class="button" href="${url}" target="_self">SCOPRI SE HAI VINTO</a>
                </div>
                
                <div class="iw-thank-you-page__info">
                    <h5>VUOI PROVARE IN UN ALTRO MOMENTO?</h5>
                    <p>Puoi riprendere la tua giocata quando vuoi,
                         all'interno della sezione "Premi e giocate".</p>
                    
                    <a href="/" target="_self">VOGLIO GIOCARE IN SEGUITO</a>
                </div>
            </div>
        `)
    }


};

export default Utility;
