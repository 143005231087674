import {SmartComponent} from "smart-component-js";

class RewardFormComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.element = element;
        this.init();
    }

    init() {
        document.addEventListener("yxp-form-loaded", () => {
            window.authProvider.auth.getLoginStatus().then((d) => {
                this.element.querySelector('input[name="name"]').value = d.profile.firstName;
                this.element.querySelector('input[name="surname"]').value = d.profile.lastName;
            }, (e) => this.element.classList.remove('loading'));
        });

        this.loadChildComponents(this);
    }
}

export default RewardFormComponent;