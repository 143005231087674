import {SmartComponentManager} from "smart-component-js";
import "../custom-event-polyfill";
import DefaultCtrl from "./controllers/DefaultCtrl";

import HomeComponent from "./components/HomeComponent";
import MenuComponent from "./components/MenuComponent";
import ScreensetComponent from "./components/ScreensetComponent";
import OnBoardingComponent from "./components/OnBoardingComponent";
import GiocaCodiceComponent from "./components/GiocaCodiceComponent";
import ReceiptComponent from "./components/ReceiptComponent";
import InstantWinComponent from "./components/InstantWinComponent";
import GiocateComponent from "./components/GiocateComponent";
import RewardFormComponent from "./components/RewardFormComponent";
import SurveyComponent from "./components/SurveyComponent";
import SurveyPageComponent from "./components/SurveyPageComponent";

import "../../styles/disneylandparis/lvz-disneylandparis.scss";


SmartComponentManager.registerComponents({
    DefaultCtrl,
    HomeComponent,
    MenuComponent,
    SurveyComponent,
    ScreensetComponent,
    SurveyPageComponent,
    OnBoardingComponent, // todo
    GiocaCodiceComponent,
    ReceiptComponent,
    InstantWinComponent,
    GiocateComponent,
    RewardFormComponent,
  
});

$(document).ready(() => {
    let $body = $('body [component="DefaultCtrl"]');
    SmartComponentManager.initComponentByName($body[0], $body.attr('component'));
});

