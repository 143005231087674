import {SmartComponent} from "smart-component-js";
import Utility from "../business/Utility";

class GiocateComponent extends SmartComponent {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
        this.$el = $(element);
        this.filterPerPage = 4;
        this.filterActivated = false;
        this.campaignCode = this.$el.attr('campaignCode');
        this.init();
    }

    init() {
        this.$el.append('<div class="yxp-activities-cnt"></div>');
        this.$itemsWrapper = this.$el.find('.yxp-activities-cnt');

        this._loadItems();
    }

    _printFilter() {
        let html = `
            <div class="filter-cnt">
                <div class="filter-title">
                    <span class="filter-title-label">Filtra per</span>
                    <span class="filter-close-btn"></span>
                </div>
                <div class="filter-list"></div>
                <div class="filter-bottom">
                    <span class="filter-reset">Cancella tutto</span>
                    <span class="filter-show disabled">Mostra risultati<span class="filter-count"></span></span>
                </div>
            </div>
        `

        this.$itemsWrapper.append(html);

        if (!this.filterActivated) {
            this.filterActivated = true;
            this._printFilterList();
        }

        //mostro pulsante filtri se almeno due tipologie diverse, di cui una hasCta oppure se sono presenti entrambe le tipologie di hasCta
        if (($('[data-cta="hasCta"]').length > 0 && $('[data-cta="hasntCta"]').length > 0) || ($('[data-type="to-be-completed"]').length > 0 && $('[data-type="reward-request-todo"]').length > 0 && $('[data-cta="hasntCta"]').length == 0)) {
            $('.activity-filter-btn').removeClass('yxpHidden');
        }

        $('.activity-filter-btn').on('click', () => {
            $('body').addClass('no-scroll');
            $('.mie-sfide-cnt').addClass('filter-visible');
            $('.filter-cnt').css('display', 'flex').fadeIn(0);
        });

        $('.filter-close-btn').on('click', () => {
            $('body').removeClass('no-scroll');
            if (!Utility.isMobile()) {
                $('.filter-cnt').fadeOut(0);
            }
            ;

            $('.mie-sfide-cnt').removeClass('filter-visible');
        });
    }

    _printFilterList() {
        let playsArray = [];
        $('.yxp-activity-row').each(function () {
            if ($(this).data('cta') == 'hasCta') {
                if (!playsArray.includes($(this).data('type'))) {
                    playsArray.push($(this).data('type'));
                }
                ;
            }
        });
        let that = this;
        $(playsArray).each((i, v) => {
            that._printSingleFilter(v);
        });
    }

    _filterPaginationManager(type) {
        $('.pagination-btn').addClass('yxpHidden');

        if ($('.yxp-activity-row').filter('[data-type="' + type + '"]').length >= this.filterPerPage) {
            $('.filter-pagination-btn').removeClass('yxpHidden');

            if ($('.filter-pagination-btn').length < 1) {
                $('.yxp-activity-row').filter('[data-type="' + type + '"]').last().after('<div class="button filter-pagination-btn mt-50">CARICA ALTRI</div>');
            }
        } else {
            $('.filter-pagination-btn').addClass('yxpHidden');
        }

        let filterPage = 1;

        $('.yxp-activity-row').filter('[data-type="' + type + '"]').addClass('yxpHidden');
        $('.yxp-activity-row').filter('[data-type="' + type + '"]').slice(0, this.filterPerPage * filterPage).removeClass('yxpHidden');

        $('.filter-pagination-btn').on('click', () => {
            filterPage++;
            $('.yxp-activity-row').filter('[data-type="' + type + '"]').slice(this.filterPerPage * filterPage - this.filterPerPage, this.filterPerPage * filterPage).removeClass('yxpHidden');
            if ($('.yxp-activity-row').filter('[data-type="' + type + '"]').length <= this.filterPerPage * filterPage) {
                $('.filter-pagination-btn').addClass('yxpHidden');
            } else {
                $('.filter-pagination-btn').removeClass('yxpHidden');
            }
        });
    }

    _paginationManager() {
        let filterPage = 1;
        if ($('.yxp-activity-row').length > this.filterPerPage * filterPage) {
            $('.yxp-activity-row').addClass('yxpHidden');
            $('.yxp-activity-row').slice(0, this.filterPerPage * filterPage).removeClass('yxpHidden');

            if ($('.pagination-btn').length < 1) {
                $('.yxp-activity-row').last().after('<div class="button pagination-btn mt-50">CARICA ALTRI</div>')
            }
        }

        $('.pagination-btn').on('click', () => {
            filterPage++;
            $('.yxp-activity-row').slice(this.filterPerPage * filterPage - this.filterPerPage, this.filterPerPage * filterPage).removeClass('yxpHidden');
            if ($('.yxp-activity-row').length <= this.filterPerPage * filterPage) {
                $('.pagination-btn').addClass('yxpHidden');
            } else {
                $('.pagination-btn').removeClass('yxpHidden');
            }
        });
    }

    _printSingleFilter(type) {
        let typeLabel = type === 'reward-request-todo' ? 'Premi da richiedere' : 'Giocate da completare';
        let typeImg = type === 'reward-request-todo' ? 'win' : 'complete';
        let html = `
            <div class="plays-filter-cnt">
                <img alt="${typeImg}" class="filter-img" src="/fe-web/img/emozionecalcio/activities/${typeImg}.svg" />
                <span class="filter-label">${typeLabel}</span>
                <input class="filter-radio" type="radio" name="filter-type" value="${type}" />
            </div>
        `
        $('.filter-list').append(html);
        this._printFilterInteractions();
    }

    _printFilterInteractions() {
        $('.filter-radio').on('change', (e) => {
            $('.filter-show .filter-count').html('').html(' (' + $('[data-type="' + $(e.currentTarget).val() + '"]').length + ')');
            $('.filter-show').removeClass('disabled');
        });

        $('.filter-reset').on('click', () => {
            $('.filter-count').html('');
            $('.filter-show').addClass('disabled');
            $('.filter-radio').prop('checked', false);
            $('.filter-close-btn').click();
            this._showFilterResults('reset');

        });

        $('.filter-show').on('click', () => {
            $('.activity-filter-btn .filter-count').html('').html($('.filter-show .filter-count').html());
            this._showFilterResults($('.filter-radio:checked').val());
            $('.filter-close-btn').click();
        });
    }

    _showFilterResults(type) {
        if (type == 'reset') {
            $('.yxp-activity-row').removeClass('yxpHidden');
        } else {
            $('.yxp-activity-row').filter('[data-type="' + type + '"]').removeClass('yxpHidden');
            $('.yxp-activity-row').not('[data-type="' + type + '"]').addClass('yxpHidden');
        }

        this._filterPaginationManager(type);
    }

    _printItems(items) {
        let html = '<div class="yxp-activity-filter mb-30"><span class="activity-filter-btn yxpHidden">FILTRI<span class="filter-count"></span></span></div>';
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            let playsLeftLabel = typeof item.playsLeft != 'undefined' ? (item.playsLeft === 1 ? 'tentativo' : 'tentativi') : '';
            let itemCta1 = typeof item.cta != 'undefined' ? item.cta[0] : null;
            let itemCta2 = typeof item.cta != 'undefined' ? (item.cta.length === 2 ? item.cta[1] : null) : null;
            let ctaAttr = typeof item.cta != 'undefined' ? 'hasCta' : 'hasntCta';
            let subtitle = typeof item.subtitle != 'undefined'
                ? `<p class="yxp-activity-subtitle">${item.subtitle}</p>`
                : ``;
            html += `<div class="yxp-activity-row" data-type="${item.type}" data-cta="${ctaAttr}">
                <div class="yxp-activity-cnt ${item.icon ? 'icon-' + item.icon : ''}">
                    <div>
                        <p class="yxp-activity-title">${item.title}</p>
                        ${subtitle}
                        ${item.description ? `<p class="yxp-activity-description">${item.description}</p>` : ''}
                    </div>
                    
                    <div class="text-right">
                        ${this._printCta(itemCta1)}
                        ${item.description2 ? `<p class="yxp-activity-description extra">${item.description2}</p>` : ''}
                        ${item.playsLeft ? `<p class="yxp-activity-description extra">Hai ancora: ${item.playsLeft} ${playsLeftLabel}</p>` : ''}
                        ${this._printCta(itemCta2)}
                    </div>
                </div>
            </div>`;
        }

        this.$itemsWrapper.append(html);
        this._printFilter();
        this._paginationManager();
    }

    _printCta(cta) {
        if (!cta) return "";
        let ctaClass = cta.url?.includes('instant-win') ? 'instant-win-button' : 'button';
        return `<div class="${ctaClass}"> <a href="${cta.url}" target="${cta.target || '_self'}">${cta.label}</a></div>`;
    }

    _loadItems() {
        this.element.classList.add('loading');
        let url = '/api/1.0/lavazza/campaign/' + this.campaignCode + '/activities';
        youserxp.ajax(url, {
            method: 'GET',
            auth: true
        }).then((data) => {
            (data.body.results.length) ?
                this._printItems(data.body.results):
                this.$itemsWrapper.append(this._printNoResult());
            this.element.classList.remove('loading');

        }, (err) => {
            this.$itemsWrapper.append(this._printError());
            this.element.classList.remove('loading');
        });
    }

    _printError() {
        return `
            <div class="yxpType_TEXTAREA mt-30 plays-error">
                <div class="yxpHtmlTextWrapper">
                    <p>Ops, qualcosa è andato storto!</p>
                </div>
            </div>
            <div class="yxpType_LINK button  mt-30 mb-30">
                <a href="/it/reserved/premi-e-giocate">Riprova</a>
            </div>`
    }

    _printNoResult() {
        return `
            <div class="yxpType_TEXTAREA text-center">
                <div class="yxpHtmlTextWrapper">
                    <p>Non hai ancora effettuato nessuna giocata. <strong>Partecipa ora!</strong></p>
                </div>
            </div>
            <div class="yxpType_LINK button no-results mt-30 mb-30 text-center">
                <a href="/it/reserved/gioca">Gioca e vinci</a>
            </div>`;
    }
}

export default GiocateComponent;
